import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/AuthContext";

const Register = () => {
  const {
    register: registerUser,
    verifyEmail,
    resendOTP,
    tempEmail,
  } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [step, setStep] = useState(1);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const password = watch("password");

  const handleNextStep = (data) => {
    setFormData({ ...formData, ...data });
    setStep(2);
    reset({
      collegeCode: "",
      collegeRollNo: "",
      degree: "",
      currentYear: "",
    });
  };

  const onSubmit = async (data) => {
    if (step === 1) {
      handleNextStep(data);
      return;
    }

    try {
      setIsLoading(true);
      const finalData = { ...formData, ...data };
      await registerUser(finalData);
      setIsOtpSent(true);
    } catch (error) {
      console.error("Registration error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await verifyEmail(tempEmail, otp);
      navigate("/tests");
    } catch (error) {
      setOtpError("Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsResending(true);
      await resendOTP(tempEmail);
      setOtpError("");
    } catch (error) {
      setOtpError("Failed to resend OTP. Please try again later.");
    } finally {
      setIsResending(false);
    }
  };

  const renderProgressBar = () => (
    <div className="mb-8">
      <div className="flex justify-between mb-2">
        <span
          className={`text-sm ${
            step >= 1 ? "text-[#fd8b9e]" : "text-gray-400"
          }`}
        >
          Personal Info
        </span>
        <span
          className={`text-sm ${
            step >= 2 ? "text-[#fd8b9e]" : "text-gray-400"
          }`}
        >
          Academic Details
        </span>
      </div>
      <div className="h-2 bg-gray-200 rounded-full">
        <div
          className="h-full bg-[#fd8b9e] rounded-full transition-all duration-500 ease-in-out"
          style={{ width: `${step === 1 ? "50%" : "100%"}` }}
        ></div>
      </div>
    </div>
  );

  if (isOtpSent) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#151523]">
        <div className="px-8 py-6 w-full md:w-1/3 mt-4 text-left bg-white shadow-lg sm:rounded-lg">
          <h3 className="text-2xl font-bold text-center">Verify Your Email</h3>
          <form onSubmit={handleOtpSubmit} className="mt-4">
            <div>
              <label className="block" htmlFor="otp">
                Enter OTP
              </label>
              <input
                type="text"
                placeholder="Enter the OTP sent to your email"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                disabled={isLoading}
              />
              {otpError && (
                <p className="text-red-500 text-xs mt-1">{otpError}</p>
              )}
            </div>
            <div className="flex items-baseline justify-between mt-4">
              <button
                type="submit"
                className="px-6 py-2 text-[#fd8b9e] bg-[#151523] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                {isLoading ? "Verifying..." : "Verify OTP"}
              </button>
              <button
                type="button"
                onClick={handleResendOtp}
                className="text-sm text-[#fd8b9e] bg-transparent hover:underline hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isResending}
              >
                {isResending ? "Resending..." : "Resend OTP"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#151523] py-8">
      <div className="px-8 py-6 w-full md:w-1/3 bg-white shadow-lg sm:rounded-lg">
        <div className="flex justify-center items-center mb-6">
          <img
            src="https://sqilco.com/sqilco.svg"
            alt="Sqilco"
            className="w-[30%]"
          />
        </div>
        {renderProgressBar()}
        <h3 className="text-2xl font-bold text-center mb-6">
          {step === 1 ? "Personal Information" : "Academic Details"}
        </h3>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-4"
          autoComplete="off"
        >
          {step === 1 ? (
            <>
              <div>
                <label className="block" htmlFor="fullName">
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your full name"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("fullName", {
                    required: "Full name is required",
                  })}
                  disabled={isLoading}
                />
                {errors.fullName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.fullName.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block" htmlFor="phoneNumber">
                  Phone Number
                </label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  })}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) || e.target.value.length >= 10) {
                      e.preventDefault();
                    }
                  }}
                  disabled={isLoading}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block">Password</label>
                <input
                  type="password"
                  placeholder="Enter your password"
                  autoComplete="new-password"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.password.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm your password"
                  autoComplete="new-password"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("confirmPassword", {
                    required: "Please confirm your password",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                  disabled={isLoading}
                />
                {errors.confirmPassword && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mt-4">
                <label className="block" htmlFor="collegeCode">
                  College Code
                </label>
                <input
                  type="text"
                  placeholder="Enter your college code"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("collegeCode", {
                    required: "College code is required",
                  })}
                  disabled={isLoading}
                />
                {errors.collegeCode && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.collegeCode.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block" htmlFor="collegeRollNo">
                  College Roll Number
                </label>
                <input
                  type="text"
                  placeholder="Enter your college roll number"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("collegeRollNo", {
                    required: "College roll number is required",
                  })}
                  disabled={isLoading}
                />
                {errors.collegeRollNo && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.collegeRollNo.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block" htmlFor="degree">
                  Degree
                </label>
                <input
                  type="text"
                  placeholder="Enter your degree"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("degree", { required: "Degree is required" })}
                  disabled={isLoading}
                />
                {errors.degree && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.degree.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="block" htmlFor="currentYear">
                  Current Year
                </label>
                <input
                  type="number"
                  placeholder="Enter your current year"
                  autoComplete="off"
                  className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                  {...register("currentYear", {
                    required: "Current year is required",
                    min: {
                      value: 1,
                      message: "Year must be at least 1",
                    },
                    max: {
                      value: 5,
                      message: "Year cannot exceed 5",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.currentYear && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.currentYear.message}
                  </p>
                )}
              </div>
            </>
          )}

          <div className="flex items-baseline justify-between mt-6">
            {step === 2 && (
              <button
                type="button"
                onClick={() => setStep(1)}
                className="px-6 py-2 text-[#fd8b9e] border border-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                Back
              </button>
            )}
            <button
              className="px-6 py-2 text-black bg-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading
                ? step === 1
                  ? "Loading..."
                  : "Registering..."
                : step === 1
                ? "Next"
                : "Register"}
            </button>
            {step === 1 && (
              <Link
                to="/login"
                className="text-sm text-[#fd8b9e] hover:underline"
              >
                Login
              </Link>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;